<template>
    <!-- $route.query.isTitle 首页进入参数为1，后台进入无此参数 -->
    <div class="roam-view h-100">
      <div ref="cesium" id="container" class="map-warp"></div>
      <div class="point_data_info">
        <div class="point_button" :style="{ top: !$route.query.isTitle ? '20px' : '100px'}">
          <Button
            type="info"
            size="small"
            class="tool-button m-t-10"
            @click="startFly"
            >{{ roam_status }}</Button
          >
          <Button
            type="info"
            size="small"
            class="tool-button m-t-10"
            @click="stopFly"
            >暂停漫游</Button
          >
          <Button
            type="info"
            size="small"
            class="tool-button m-t-10"
            @click="continueFly"
            >继续漫游</Button
          >
          <Button
            type="info"
            size="small"
            class="tool-button m-t-10"
            @click="exitFly"
            >退出漫游</Button
          >
          <Button
            type="info"
            size="small"
            class="tool-button m-t-10"
            @click="infoFly"
            >漫游列表</Button
          >
        </div>
        <div class="point_list" v-show="pointList">
          <span
            v-for="(item, index) in marks"
            :key="index"
            class="m-r-5"
            :class="point_index == index ? 'point_active' : ''"
            @click="pointFly(index)"
            >{{ item.name }}</span>
        </div>
        <div class="point_info" v-show="pointInfoShow" :style="{ top: !$route.query.isTitle ? '20px' : '100px'}">
          <div class="line_info_top">{{ point_info.name }}</div>
          <Vgroup :labelWidth="90" class="transport">
            <Vcell label="位置：" class="bg_color">
              {{ point_info.add }}
            </Vcell>
            <Vcell
              label="简介："
              :value="point_info.info || '暂无'"
            />
          </Vgroup>
          <div class="point_panorama" v-show="pointPanorama" @click="showPointP" >查看全景</div>
        </div>
      </div>
      <PanoramaView :pointPanoramas="point_panoramas" @dialogVisibleEvent="dialogVisibleEvent" v-if="showPointPanorama" />
    </div>
  </template>
  
  <script>
  import { cesiumInit, drawTool } from "@/mixin/cesium.js";
  import { getPanoramaById } from "@/libs/api/panorama";
  import PanoramaView from "./components/panoramaView.vue"
  import { util } from '@/libs'
  import { Toast } from 'vant';
  export default {
    props: {
        marks: { type: Array },
    },
    mixins: [cesiumInit, drawTool],
    components: { PanoramaView },
    data() {
      return {
        cesium: null,
        tool: null,
        pointInfoShow: false,
        pointList: false,
        pointPanorama: false,
        showPointPanorama: false,
        point_index: 0,
        roam_status: '开始漫游',
        marksIndex: 0,
        pitchValue: -20,
        changeCameraTime: 8,
        Exection: {},
        point_info: {},
        point_panoramas: null,
        color: {
          0: "rgb(57, 204, 196)",
          1: "rgb(79, 181, 255)",
          2: "rgb(255, 161, 89)",
          3: "rgb(78, 138, 255)",
          4: "#7232dd",
          5: "rgb(57, 204, 196)",
        },
        color2: {
          0: "magenta",
          1: "volcano",
          2: "orange",
          3: "cyan",
          4: "geekblue",
          5: "purple",
        },
      }
    },
    methods: {
      showPointP() {
        let id = this.point_info.point360
        console.log(id)
        if(id) {
          console.log(111)
          getPanoramaById({ id }).then(({ code, data }) => {
            if (code == "HA0200") {
              // this.panorama = data
              console.log(data)
              this.point_panoramas = data
              this.showPointPanorama = true
            }
          })
        }else {
          Toast('当前无全景图片！')
        }
      },
      dialogVisibleEvent(val) {
        this.showPointPanorama = val
      },
      initFly() {
        const { viewer, marks, point_info, pointInfoShow, pointList, pointPanorama } = this
        // eslint-disable-next-line no-unused-vars
        const self = this;
        self.pointList = false
        self.pointPanorama = false
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(marks[0].lng, marks[0].lat, marks[0].height),  //定位坐标点，建议使用谷歌地球坐标位置无偏差
          duration: 4,   //定位的时间间隔
          complete: function() {
            // self.point_info = marks[0].flytime
            self.pointInfoShow = true
            self.point_info = Object.assign({}, self.point_info, {
                ...marks[0],
            });
          },
        });
      },
      flyExtent() {
        const { viewer, marks, pitchValue, point_info, pointInfoShow, pointList, pointPanorama, roam_status } = this
        const self = this;
        // 相机看点的角度，如果大于0那么则是从地底往上看，所以要为负值
        const pitch = Cesium.Math.toRadians(pitchValue);
        // 时间间隔2秒钟
        this.setExtentTime(marks[this.marksIndex].flytime);
        this.Exection = function TimeExecution() {
          let preIndex = self.marksIndex - 1;
          if (self.marksIndex == 0) {
            preIndex = marks.length - 1;
          }
          //计算俯仰角
          let heading = self.bearing(marks[preIndex].lat, marks[preIndex].lng, marks[self.marksIndex].lat, marks[self.marksIndex].lng);
          heading = Cesium.Math.toRadians(heading);
          // 当前已经过去的时间，单位s
          const delTime = Cesium.JulianDate.secondsDifference(viewer.clock.currentTime, viewer.clock.startTime);
          const originLat = self.marksIndex == 0 ? marks[marks.length - 1].lat : marks[self.marksIndex - 1].lat;
          const originLng = self.marksIndex == 0 ? marks[marks.length - 1].lng : marks[self.marksIndex - 1].lng;
          const endPosition = Cesium.Cartesian3.fromDegrees(
            (originLng + (marks[self.marksIndex].lng - originLng) / marks[self.marksIndex].flytime * delTime),
            (originLat + (marks[self.marksIndex].lat - originLat) / marks[self.marksIndex].flytime * delTime),
            marks[self.marksIndex].height
          );
          self.pointInfoShow = false
          self.pointList = false
          self.pointPanorama = false
          viewer.scene.camera.flyTo({
            destination: endPosition,
            // orientation: {
            //   heading: heading,
            //   pitch: pitch,
            //   roll: 0
            // },
            complete: function() {
              self.pointInfoShow = true
              self.point_info = Object.assign({}, self.point_info, {
                  ...marks[self.marksIndex],
              });
              if(self.marksIndex == marks.length - 1) {
                self.roam_status = '漫游结束'
              }
            },
          });
          if (Cesium.JulianDate.compare(viewer.clock.currentTime, viewer.clock.stopTime) >= 0) {
            viewer.clock.onTick.removeEventListener(self.Exection);
            //有个转向的功能
            self.changeCameraHeading();
          }
        };
        viewer.clock.onTick.addEventListener(self.Exection);
      },
      // 相机原地定点转向
      changeCameraHeading() {
        const { viewer, marks, pitchValue, changeCameraTime } = this
        const self = this;
        let { marksIndex } = this
  
        let nextIndex = this.marksIndex + 1;
        if (marksIndex == marks.length - 1) {
          nextIndex = 0;
        }
        // 计算两点之间的方向
        // const heading = this.bearing(marks[marksIndex].lat, marks[marksIndex].lng, marks[nextIndex].lat, marks[nextIndex].lng);
        // 相机看点的角度，如果大于0那么则是从地底往上看，所以要为负值
        // const pitch = Cesium.Math.toRadians(pitchValue);
        // 给定飞行一周所需时间，比如10s, 那么每秒转动度数
        // const angle = (heading - Cesium.Math.toDegrees(viewer.camera.heading)) / changeCameraTime;
        // 时间间隔2秒钟
        this.setExtentTime(changeCameraTime);
        // 相机的当前heading
        // const initialHeading = viewer.camera.heading;
        this.Exection = function TimeExecution() {
          // 当前已经过去的时间，单位s
          // const delTime = Cesium.JulianDate.secondsDifference(viewer.clock.currentTime, viewer.clock.startTime);
          // const heading = Cesium.Math.toRadians(delTime * angle) + initialHeading;
          // viewer.scene.camera.setView({
          //   orientation: {
          //     heading: heading,
          //     pitch: pitch,
          //   }
          // });
          if (Cesium.JulianDate.compare(viewer.clock.currentTime, viewer.clock.stopTime) >= 0) {
            viewer.clock.onTick.removeEventListener(self.Exection);
            self.marksIndex = ++self.marksIndex >= marks.length ? 0 : self.marksIndex;
            if (self.marksIndex != 0) {
              self.flyExtent();
            }
          }
        };
        viewer.clock.onTick.addEventListener(self.Exection);
      },
      // 设置飞行的时间到viewer的时钟里
      setExtentTime(time) {
        const { viewer } = this;
        const startTime = Cesium.JulianDate.fromDate(new Date());
        const stopTime = Cesium.JulianDate.addSeconds(startTime, time, new Cesium.JulianDate());
        viewer.clock.startTime = startTime.clone();  // 开始时间
        viewer.clock.stopTime = stopTime.clone();     // 结速时间
        viewer.clock.currentTime = startTime.clone(); // 当前时间
        viewer.clock.clockRange = Cesium.ClockRange.CLAMPED; // 行为方式-达到终止时间后停止
        viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK; // 时钟设置为当前系统时间; 忽略所有其他设置。
      },
      /** 相机视角飞行 结束 **/
      /** 飞行时 camera的方向调整(heading) 开始 **/
      // 角度转弧度
      toRadians(degrees) {
        return degrees * Math.PI / 180;
      },
      // 弧度转角度
      toDegrees(radians) {
        return radians * 180 / Math.PI;
      },
      //计算俯仰角
      bearing(startLat, startLng, destLat, destLng) {
        startLat = this.toRadians(startLat);
        startLng = this.toRadians(startLng);
        destLat = this.toRadians(destLat);
        destLng = this.toRadians(destLng);
  
        let y = Math.sin(destLng - startLng) * Math.cos(destLat);
        let x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let brng = Math.atan2(y, x);
        let brngDgr = this.toDegrees(brng);
  
        return (brngDgr + 360) % 360;
      },
      //开始飞行
      startFly() {
        if(this.roam_status === '漫游中...') {
          Toast('已在漫游中，请勿重复点击...')
          return
        }
        this.roam_status = '漫游中...'
        const { Exection } = this;
        if (Object.keys(Exection).length > 0) {
          this.exitFly();
        }
        this.flyExtent();
      },
      //停止飞行
      stopFly() {
        this.viewer.clock.shouldAnimate = false;
      },
      //继续飞行
      continueFly() {
        this.viewer.clock.shouldAnimate = true;
      },
      //退出飞行
      exitFly() {
        this.roam_status = '开始漫游'
        const { Exection, viewer, marks, pitchValue, pointInfoShow, pointList, pointPanorama } = this
        const self = this
        viewer.clock.onTick.removeEventListener(Exection);
        // 相机看点的角度，如果大于0那么则是从地底往上看，所以要为负值
        const pitch = Cesium.Math.toRadians(pitchValue);
        const marksIndex = 1;
        let preIndex = marksIndex - 1;
        //计算俯仰角
        let heading = this.bearing(marks[preIndex].lat, marks[preIndex].lng, marks[marksIndex].lat, marks[marksIndex].lng);
        heading = Cesium.Math.toRadians(heading);
        const endPosition = Cesium.Cartesian3.fromDegrees(
          marks[0].lng,
          marks[0].lat,
          marks[0].height
        );
        self.pointInfoShow = false
        self.pointList = false
        self.pointPanorama = false
        viewer.scene.camera.flyTo({
          destination: endPosition,
          // orientation: {
          //   heading: heading,
          //   pitch: pitch,
          //   roll: 0
          // },
          complete: function() {
            self.pointInfoShow = true
            self.point_info = Object.assign({}, self.point_info, {
                  ...marks[0],
              });
          },
        });
      },
      infoFly() {
        // 显示列表时退出漫游
        this.exitFly()
        if(!this.pointList) {
          this.pointList = true
          this.pointPanorama = true
        }else {
          this.pointList = false
          this.pointPanorama = false
        }
      },
      pointFly(index) {
        this.point_index = index
        const { viewer, marks, point_info, pointInfoShow } = this
        // eslint-disable-next-line no-unused-vars
        const self = this;
        self.pointInfoShow = false
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(marks[index].lng, marks[index].lat, marks[index].height),  //定位坐标点，建议使用谷歌地球坐标位置无偏差
          duration: 4,   //定位的时间间隔
          complete: function() {
            self.pointInfoShow = true
            self.point_info = Object.assign({}, self.point_info, {
                ...marks[index],
            });
          },
        });
      }
    },
    async mounted() {
        // console.log(this.marks)
        this.cesiumMap(this.$refs.cesium);
        this.drawInit();
    },
  }
  </script>
  
  <style lang="less" scoped>
  
  .roam-view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .title {
      height: 30px;
    }
    .point_panorama {
      cursor: pointer;
      text-align: right;
      padding-right: 8px;
    }
    .extra {
      position: absolute;
      right: 30px;
      top: 20px;
    }
    .map-warp {
      height: 100%;
      position: relative;
      .map_tool {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
    .point_info {
      position: absolute;
      top: 60px;
      left: 20px;
      width: 40%;
      color: white;
      padding: 20px;
      background: rgba(0,0,0,.4);
      .line_info_top {
        font-size: 18px;
        text-align: center;
      }
      .bg_color {
        background: rgba(24, 123, 206, 0.2);
      }
    }
    .point_button {
      position: absolute;
      top: 70px;
      right: 30px;
      width: 80px;
    }
    .point_list {
      position: absolute;
      bottom: 44px;
      left: 25px;
      width: calc(100% - 50px);
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      span {
        height: 30px;
        line-height: 30px;
        padding: 0 16px;
        border-radius: 3px;
        color: #333;
        background: #ffffff;
        cursor: pointer;
        white-space: nowrap;
      }
      .point_active {
        color: #ffffff;
        background: #2db7f5;
      }
    }
  }
  </style>